














































































import {Component} from 'vue-property-decorator'
import {AdminPermission} from '~/utils/permissions'
import {createRequest} from '~/utils/network-request'
import {mixins} from 'vue-class-component'
import QueryParserMixin from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import LoadingMixin from '~/mixins/loading-mixin'
import NumberDatePicker from '~/components/banner/number-date-picker.vue'

interface Broadcast {
    _id: string
    name: string
}

@Component({
    components: {NumberDatePicker},
    metaInfo() {
        return {
            title: 'WhatsApp Broadcast Manager'
        }
    }
})
export default class WhatsAppBroadcastManager extends mixins(QueryParserMixin, PaginationMixin, LoadingMixin) {
    static permission = AdminPermission.SendWhatsapp

    keyword: string = ''
    step = 20

    broadcasts: Broadcast[] = []
    activeBroadcast: Broadcast = {_id: '', name: ''}

    message: string = ''
    schedule = 0

    newBroadcast: boolean = false

    cLoading: boolean = false
    showDetails: boolean = false
    async created() {
        await this.updateBroadcasts()
    }
    async toPage(page: number) {
        return this.updateBroadcasts(page)
    }
    async updateBroadcasts(page: number = 1) {
        this.showDetails = false
        this.startLoading('#broadcast-table')
        const res = await createRequest('/chat-api/whatsapp-broadcasts', 'get', {
            keyword: this.keyword,
            limit: this.step,
            skip: (page - 1) * this.step
        }).send()
            .finally(() => {
                this.stopLoading('#broadcast-table')
            })
        this.broadcasts = res.data.broadcasts
        this.totalCount = res.data.count
    }
    async rowClicked(row) {
        this.activeBroadcast = row
        this.newBroadcast = false
        this.showDetails = true
    }
    async submitBroadcast() {
        if(this.schedule) {
            await createRequest('/bulk-messages/broadcast', 'post', {}, {
                message: this.message,
                chat_id: this.activeBroadcast._id,
                broadcast_name: this.activeBroadcast.name,
                scheduled: this.schedule
            }).send()
            this.message = ''
            await this.$message.success('Submitted')
        } else {
            await createRequest(`/chat-api/whatsapp-broadcast/${this.activeBroadcast._id}/message`, 'post', {}, {
                message: this.message
            }).send()
            this.message = ''
            await this.$message.info('Registered, please wait for ChatAPI')
        }
    }

    dsync = false
    async sync() {
        this.dsync = true
        this.startLoading('#sync-btn')
        await createRequest(`/chat-api/whatsapp-broadcasts/sync`, 'post').send()
            .finally(() => {
                this.stopLoading('#sync-btn')
                this.dsync = false
            })
        await this.updateBroadcasts()
    }
}

