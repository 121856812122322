








import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class NumberDatePicker extends Vue {
    @Prop()
    value: number

    get dValue() {
        if (this.value)
            return new Date(this.value)
        else
            return null
    }

    set dValue(v: Date | null) {
        if (v)
            this.$emit('input', v!.getTime())
        else
            this.$emit('input', 0)
    }
}
